import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const FileManagementService = {
    fetchFileManagementRecords,
    postFileManagementForm
};

function fetchFileManagementRecords(iamportKey, environment, paymentChannel) {
    const requestOptions = {
      method: 'GET',
      headers: Headers.apiHeaders(),
      credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment, payment_channel_key: paymentChannel});
    return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/recon/file-mapping?` + queryParams, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function postFileManagementForm(payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/file-mapping`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}