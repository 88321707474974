import { MerchantService } from '../../helpers/backend/merchant.service';
import { PaymentGatewayService } from '../../helpers/backend/payment_gateway.service';
import * as cloneDeep from "lodash.clonedeep";
import {
  UPDATE_MERCHANT_ENTITY_DETAILS,
  FETCH_MERCHANT_ENTITY_DETAILS,
  FETCH_PAYMENT_CHANNEL_CREDENTIALS,
  UPDATE_PAYMENT_CHANNEL_CREDENTIALS,
  FETCH_PAYOUT_CHANNEL_CREDENTIALS,
  UPDATE_PAYOUT_CHANNEL_CREDENTIALS,
  UPDATE_MERCHANT_LOGO,
} from '../actions.type';
import {
  SET_ENTITY_DETAILS_IN_STORE,
  SET_LOGO_IN_STORE,
  UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE,
  UPDATE_PAYOUT_CHANNEL_DETAILS_IN_STORE,
} from '../mutations.type';

export const state = {
  entityDetails: {
    name: "", brand_name: "", website_url: "", iamportSecret: "", isVerifyPayoutsEnabled:true, master_merchant_access:true, logo: ""
  },
  paymentChannelDetails: {
  },
  payoutChannelDetails: {
  },
}

export const mutations = {
  [SET_ENTITY_DETAILS_IN_STORE](state, entityDetails) {
    state.entityDetails = { ...state.entityDetails, ...entityDetails };
  },
  [SET_LOGO_IN_STORE](state, logo) {
    state.entityDetails.logo = logo;
  },
  [UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE](state, { entityKey, entityDetails }) {
    state.paymentChannelDetails[entityKey] = entityDetails
    state.paymentChannelDetails = cloneDeep(state.paymentChannelDetails)
  },
  [UPDATE_PAYOUT_CHANNEL_DETAILS_IN_STORE](state, { entityKey, entityDetails }) {
    state.payoutChannelDetails[entityKey] = entityDetails
    state.payoutChannelDetails = cloneDeep(state.payoutChannelDetails)
  },
}

export const getters = {
  getMerchantEntityDetails(state) {
    return state.entityDetails;
  },
  getPaymentChannelCredentials(state) {
    return state.paymentChannelDetails;
  },
  getPayoutChannelCredentials(state) {
    return state.payoutChannelDetails;
  }
}

export const actions = {

  [UPDATE_MERCHANT_ENTITY_DETAILS](context, newEntityDetails) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      MerchantService.updateMerchantEntityDetails(merchantUUID, newEntityDetails).then((response) => {
        const entityDetails = response
        context.commit(SET_ENTITY_DETAILS_IN_STORE, entityDetails)
        resolve(entityDetails);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(errorObject);
      })
    })
  },

  [UPDATE_MERCHANT_LOGO](context, logo) {
    context.commit(SET_LOGO_IN_STORE, logo)
  },

  [FETCH_MERCHANT_ENTITY_DETAILS](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      MerchantService.fetchMerchantEntityDetails(merchantUUID).then((response) => {
        const entityDetails = response
        context.commit(SET_ENTITY_DETAILS_IN_STORE, entityDetails)
        // context.commit(`auth/${SET_AUTH_USER}`, entityDetails, { root: true })
        resolve(entityDetails);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [UPDATE_PAYMENT_CHANNEL_CREDENTIALS](context, { channelId, payChannel }) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      context.commit(UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE, { entityKey: channelId, entityDetails: payChannel })
      const updatedCredentials = payChannel.creds;
      PaymentGatewayService.updatePaymentChannelCredentials(merchantUUID, channelId, updatedCredentials).then((response) => {
        context.dispatch(`${FETCH_PAYMENT_CHANNEL_CREDENTIALS}`);
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_CHANNEL_CREDENTIALS](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      PaymentGatewayService.getPaymentChannelCredentials(merchantUUID).then((response) => {
        (response || []).forEach((payChannel) => {
          context.commit(UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE, {entityKey: payChannel.key, entityDetails: payChannel})
        });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [UPDATE_PAYOUT_CHANNEL_CREDENTIALS](context, { channelId, payChannel }) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      context.commit(UPDATE_PAYOUT_CHANNEL_DETAILS_IN_STORE, { entityKey: channelId, entityDetails: payChannel })
      const updatedCredentials = payChannel.creds;
      PaymentGatewayService.updatePayoutChannelCredentials(merchantUUID, channelId, updatedCredentials).then((response) => {
        context.dispatch(`${FETCH_PAYOUT_CHANNEL_CREDENTIALS}`);
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYOUT_CHANNEL_CREDENTIALS](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      PaymentGatewayService.getPayoutChannelCredentials(merchantUUID, environment).then((response) => {
        (response || []).forEach((payoutChannel) => {
          context.commit(UPDATE_PAYOUT_CHANNEL_DETAILS_IN_STORE, {entityKey: payoutChannel.key, entityDetails: payoutChannel})
        });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
}

export const namespaced = true;
