import { BeneficiaryService } from '../../helpers/backend/beneficiary.service';
import * as cloneDeep from "lodash.clonedeep";

import {
    FETCH_BENEFICIARY_TABLE,
    CREATE_BENEFICIARY,
    UPDATE_BENEFICIARY,
    UPDATE_BENEFICIARY_ACCOUNT,
    FETCH_BANKS_LIST,
    FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT


} from '../actions.type';
import {
    UPDATE_BENEFICIARY_DATA_IN_STORE,
    CREATE_BENEFICIARY_DATA_IN_STORE,
    UPDATE_BENEFICIARY_ACCOUNT_IN_STORE,
    TOGGLE_VENDORS_LOADER_IN_STORE,
    ADD_BANKS_LIST_IN_STORE
} from '../mutations.type';

export const state = {
    content: [],
    isFetchingData: false,
    total_elements: 0,
    total_pages: 0,
    size: 0,
    page: 0,
    banks_list: {},
    number_of_elements: 0,
}

export const mutations = {
    [TOGGLE_VENDORS_LOADER_IN_STORE](state, isFetching) {
        state.isFetchingData = isFetching;
    },
    [ADD_BANKS_LIST_IN_STORE](state, banksdata) {
        state.banks_list = banksdata
      },
    [UPDATE_BENEFICIARY_DATA_IN_STORE](state, beneficiaryData) {
        state.content = cloneDeep(beneficiaryData.content);
        state.content = beneficiaryData.content;
        state.total_elements = beneficiaryData.total_elements;
        state.total_pages = beneficiaryData.total_pages;
        state.size = beneficiaryData.size;
        state.page = beneficiaryData.page;
        state.number_of_elements = beneficiaryData.number_of_elements;
    },
    [CREATE_BENEFICIARY_DATA_IN_STORE](state, beneficiaryData) {
        state.isFetchingData = beneficiaryData.isFetchingData;
        state.content = beneficiaryData.content;
        state.beneficiaryData = beneficiaryData.content;
        state.total_elements = beneficiaryData.total_elements;
        state.total_pages = beneficiaryData.total_pages;
        state.size = beneficiaryData.size;
        state.page = beneficiaryData.page;
        state.number_of_elements = beneficiaryData.number_of_elements;
    },
    [UPDATE_BENEFICIARY_ACCOUNT_IN_STORE](state, beneficiaryData) {
        state.isFetchingData = beneficiaryData.isFetchingData;
        state.content = beneficiaryData.content;
        state.beneficiaryData = beneficiaryData.content;
        state.total_elements = beneficiaryData.total_elements;
        state.total_pages = beneficiaryData.total_pages;
        state.size = beneficiaryData.size;
        state.page = beneficiaryData.page;
        state.number_of_elements = beneficiaryData.number_of_elements;
    },
}

export const getters = {
    getBeneficiaryList(state) {
        return state.beneficiaryData;
    }
}
export const actions = {

    [FETCH_BENEFICIARY_TABLE](context, payload) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, true);
            payload.environment = context.rootState.auth.environment;
            BeneficiaryService.fetchBeneficiaryTable(key, payload).then((response) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                context.commit(UPDATE_BENEFICIARY_DATA_IN_STORE, response.content);
                resolve(response);
            }).catch((error) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },

    [FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT](context, payload) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, true);
            payload.environment = context.rootState.auth.environment;

            BeneficiaryService.fetchBeneficiaryTableMasterMerchant(key, payload).then((response) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                context.commit(UPDATE_BENEFICIARY_DATA_IN_STORE, response.content);
                resolve(response);
            }).catch((error) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },

    [FETCH_BANKS_LIST](context, channel_key = "ALL") {
        return new Promise((resolve, reject) => {
            //const key = context.rootState.auth.currentUser.iamportKey;
            //context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, true);
            const environment = context.rootState.auth.environment;
            const iamportKey = context.rootState.auth.currentUser.iamportKey;
            BeneficiaryService.fetchBanksList(iamportKey, channel_key, environment).then((response) => {
                context.commit(ADD_BANKS_LIST_IN_STORE, response)
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },


    [CREATE_BENEFICIARY](context, newBeneficiary) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            const environment = context.rootState.auth.environment;
            newBeneficiary['environment'] = environment;
            BeneficiaryService.createBeneficiary(key, newBeneficiary).then((response) => {
                const beneficiaryData = response
                context.commit(CREATE_BENEFICIARY_DATA_IN_STORE, beneficiaryData)
                resolve(response);
                context.commit('notification/success', "Beneficiary Created Successfully", { root: true })
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(errorObject);
            })
        })
    },

    [UPDATE_BENEFICIARY](context, payload) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            payload['environment'] = context.rootState.auth.environment;
            BeneficiaryService.updateBeneficiary(key, payload).then((response) => {
                resolve(response);
                context.commit('notification/success', "Beneficiary Updated Successfully", { root: true })
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(errorObject);
            })
        })
    },

    [UPDATE_BENEFICIARY_ACCOUNT](context, { uuid, addAccount }) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            const environment = context.rootState.auth.environment;
            const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
            addAccount['environment'] = environment;
            BeneficiaryService.updateBeneficiaryAccount(merchantUUID, { uuid, key, addAccount }).then((response) => {
                context.commit('notification/success', "Beneficiary Account Updated successfully", { root: true })
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },
}


export const namespaced = true;