export const SET_AUTH_USER = "setAuthUser";
export const SET_USER_LANGUAGE_IN_STORE = "setUserLanguageInStore";
export const ADD_WEBHOOKS_IN_STORE = "addAllWebhooksInStore";
export const ADD_WEBHOOK_IN_STORE = "addWebhookInStore";
export const UPDATE_WEBHOOK_IN_STORE = "updateWebhookInStore";
export const UPDATE_WEBHOOK_BY_FORM_IN_STORE = "updateWebhookByFormInStore";
export const UPDATE_SECRET_KEY_BY_FORM_IN_STORE = "updateSecretKeyByFormInStore";
export const SET_SANDBOX_MODE_ALERT_IN_STORE = "setSandboxModeAlertInStore";
export const SET_UPDATE_ALERT_IN_STORE = 'setUpdateAlertInStore';

export const SET_ENTITY_DETAILS_IN_STORE = "setEntityDetailsInStore";
export const SET_LOGO_IN_STORE = "setLogoInStore";
export const UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE = "updatePaymentChannelDetailsInStore";
export const UPDATE_PAYOUT_CHANNEL_DETAILS_IN_STORE = "updatePayoutChannelDetailsInStore";
export const ADD_TRANSACTIONS_IN_STORE = "addTransactionInStore";
export const ADD_CHANNEL_LIST_IN_STORE = "addTChannelLisInStore";
export const UPDATE_TRXN_FETCHING_STATE = "updateIsFetchingTransaction";

export const ADD_TRANSACTION_STATUS_IN_STORE = "addTransactionStatusInStore";
export const ADD_TRANSACTION_STATISTICS_IN_STORE = "addTransactionStatisticsInStore";
export const ADD_TRANSACTION_STATS_IN_STORE = "addTransactionStatsInStore";
export const ADD_LATEST_TXNS_IN_STORE = "addLatestTxnsInStore";
export const ADD_TRANSACTION_VOLUME_IN_STORE = "addTransactionVolumeInStore";
export const ADD_TRANSACTION_SEASONALITY_IN_STORE = "addTransactionSeasonalityInStore";
export const ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE = "addTransactionVolumeByStatusInStore";
export const ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE = "addTransactionVolumeByPmtChannelInStore";
export const ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE_NEW = "addTransactionVolumeByPmtChannelInStoreNew";
export const ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE = "addTransactionVolumeByPmtMethodInStore";
export const ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE_NEW = "addTransactionVolumeByPmtMethodInStoreNew";
export const ADD_TRANSACTION_STACKED_BAR_IN_STORE = "addTransactionStackedBarInStore";
export const SET_SELECTED_TRANSACTION_ID_IN_STORE = "setSelectedTransactionIdInStore";
export const SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE = "setWebhookDetailsForSelectedTransactionIdInStore";
export const SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE = "setFailoverDetailsForSelectedTransactionIdInStore";
export const SET_TRANSACTION_DETAILS_IN_STORE = "setTransactionDetailsInStore";
export const UPDATE_TRXN_STATUS_IN_STORE = "updateTransactionStatusInStore";
export const UPDATE_DASHBOARD_FETCHING_STATE = "updateIsFetchingDashboard";

export const ADD_REFUND_DATA_FOR_SELECTED_TRANSACTION_IN_STORE = "addRefundDataForSelectedTransactionInStore";
export const SET_PAYMENT_LINKS_IN_STORE = "setPaymentLinksInStore";
export const UPDATE_PAYMENT_LINKS_DETAILS_IN_STORE = "updatePaymentLinksDetailsInStore";
export const ADD_PAYMENTLINKS_IN_STORE = 'addPaymentLinksOnStore';
export const UPDATE_PAYMENT_LINKS_FETCHING_STATE = "updateIsFetchingPaymentLinks";
export const UPDATE_SUB_USER_DETAILS_IN_STORE = "updateSubUserDetailsInStore";
export const ADD_PAYMENT_LINK_DETAIL_IN_STATE = "addPaymentLinkDetailInStore";
export const UPDATE_PAYMENT_LINK_TRANSACTIONS_IN_STATE = "updatePaymentLinkTransactionsInStore";
export const ADD_IMPORT_CREDS_IN_STORE = "addImportCredsInStore";
export const UPDATE_IAMPORT_CREDENTIALS_IN_STORE = "updateIamportCredentialsInStore";
export const ADD_SOURCE_LIST_IN_STORE = "addSourceListInStore";

//Payouts
export const UPDATE_VIRTUAL_ACCOUNT_IN_STORE = 'updateVirtualAccounts';

export const CREATE_VIRTUAL_ACCOUNT_IN_STORE = 'createVirtualAccount';

export const UPDATE_PAYOUTS_DATA_IN_STORE = 'updatePayoutsData';

export const TOGGLE_PAYOUTS_LOADER_IN_STORE = 'togglePayoutsLoader';

export const UPDATE_ACCOUNTS_DATA_IN_STORE = 'updateAccountsData';

export const APPROVE_PAYOUTS_DATA_IN_STORE = 'approvePayouts';

export const UPDATE_BENEFICIARY_DATA_IN_STORE = 'updateBeneficiary';


export const CREATE_BENEFICIARY_DATA_IN_STORE = 'createBeneficiary';

export const UPDATE_BENEFICIARY_ACCOUNT_IN_STORE = 'updateBeneficiaryAccount';

export const GENERATE_OTP_PAYOUT_IN_STORE = "generateOtpPayout";

export const DOWNLOAD_EXCEL_STATEMENT_IN_STORE = "downloadExcelStatement";

export const TOGGLE_VENDORS_LOADER_IN_STORE = "toggleVendorsLoaderInStore"

export const TOGGLE_VIRTUAL_LOADER_IN_STORE = "toggleVirtualLoaderInStore"

export const TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE = "toggleAccountStatementLoaderInStore"

export const UPDATE_ALL_PAYMENT_CHANNEL_IN_STORE = "updateAllPaymentChannelInStore";
export const CLEAR_ALL_PAYMENT_CHANNEL_IN_STORE = "clearAllPaymentChannelInStore";
export const UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE = "updateSelectedPaymentChannelDetailsInStore";
export const UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE = "updatePaymentMethodsDetailsInStore";
export const UPDATE_PAYMENT_METHODS_DETAILS_COUNT_IN_STORE = "updatePaymentMethodsDetailsCountInStore";
export const UPDATE_ALL_PAYMENT_PAYOUT_CHANNEL_IN_STORE = "updateAllPaymentPayoutChannelInStore";
export const UPDATE_ALL_PREVIEW_PAYMENT_CHANNEL_IN_STORE = "updateAllPreviewPaymentChannelInStore";
export const UPDATE_PAYMENT_ONBOARDING_DETAILS_IN_STORE = "updatePaymentOnboardingDetailsInStore";

export const SET_UPLOADED_DATA_IN_STORE = "setUploadedDataInStore";
export const ADD_RECONCILIATION_RESULTS_IN_STORE = "addReconciliationResultsInStore";
export const ADD_RECONCILIATION_SUMMARY_RESULTS_IN_STORE = "addReconciliationSummaryResultsInStore";
export const ADD_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS_IN_STORE = "addReconciliationSummaryForPspResultsInStore";
export const ADD_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY_IN_STORE = "addPspResultsForReconciliationSummaryInStore";
export const UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE = "updateReconciliationResultsFetchingState";
export const SET_RECON_DEATILS_IN_STORE = "setReconDetailsInStore";
export const CLEAR_RECON_DEATILS_IN_STORE = "clearReconDetailsInStore";
export const ADD_RECONCILIATION_SUMMARY_IN_STORE = "addReconciliationSummaryInStore";
export const SET_SELECTED_DATE_FOR_SUMMARY = "setSelectedDateForSummary";
export const RECON_ADD_CHANNEL_LIST_IN_STORE = "reconAddTChannelLisInStore";
export const ADD_FOOD_TECH_STORE_LIST = "addFoodTechStoreList";
export const ADD_RECON_TRANSACTIONS_IN_STORE = "addReconTransactionInStore";

// Recon Analytics
export const SET_RECON_SUMMARY = "setReconSummary";
export const SET_RECON_TRANSACTIONS = "setReconTransactions";

export const UPDATE_MASTER_MERCHANT_FETCHING_STATE = "updateMasterMerchantFetchingState";
export const ADD_MASTER_MERCHANT_LIST_IN_STORE = "addMasterMerchantListInStore";
export const ADD_MASTER_MERCHANT_PAGINATED_LIST_IN_STORE = "addMasterMerchantPaginatedListInStore";
export const ADD_INVITED_MASTER_MERCHANT_LIST_IN_STORE = "addInvitedMasterMerchantListInStore";
export const ADD_BANKS_LIST_IN_STORE = "addBanksListInStore";
export const UPDATE_ONBOARDING_FORM_DATA = "updateOnboardingFormData";
export const UPDATE_PAYMENT_CHANNEL_ONBOARDING_FORM_DATA = "updatePaymentChannelOnboardingFormData";
export const RECON_METABASE_RESULT_IN_STORE = "reconMetabaseResultInStore";

//Payment Pages
export const UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE = "updatePaymentPageResultsFetchingState";
export const CREATE_PAYMENT_PAGE_RECORD_IN_STORE = "createPaymentPageRecordInStore";
export const ADD_PAYMENT_PAGE_RESULTS_IN_STORE = "addPaymentPageResultsInStore";
export const ADD_PAYMENT_PAGE_DETAIL_IN_STATE = "addPaymentPageDetailInStore";
export const ADD_PREVIEW_PAYMENT_PAGE_DETAIL_IN_STATE = "addPreviewPaymentPageDetailInStore";
export const UPDATE_PAYMENT_PAGE_TRANSACTIONS_IN_STATE = "updatePaymentPageTransactionsInStore";
export const UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE = "updatePaymentPageDetailInStore";
export const UPLOAD_PAGE_IMAGE_IN_STORE = "uploadPageImageInStore";

//Fee Management
export const FEE_MANAGEMENT_ADD_CHANNEL_LIST_IN_STORE = 'feeManagementAddChannelListInStore';
export const FEE_MANAGEMENT_ADD_FEE_CONFIG_META_IN_STORE = 'feeManagementAddFeeConfigMetaInStore';
export const FEE_MANAGEMENT_ADD_FEE_CONFIG_RECORDS_IN_STORE = 'feeManagementAddFeeConfigRecordsInStore';
export const FEE_MANAGEMENT_ADD_TOLERANCE_LEVEL_IN_STORE = 'feeManagementAddToleranceLevelInStore';
export const UPDATE_FEE_CONFIG_SFTP_DETAILS_IN_STORE = 'updateFeeConfigSFTPDetailsInStore';
export const UPDATE_FEE_CONFIG_PSP_EMAIL_DETAILS_IN_STORE = 'updateFeeConfigPSPEmailDetailsInStore';
export const UPDATE_FEE_CONFIG_TOLERANCE_LEVEL_IN_STORE = 'updateFeeConfigToleranceLevelInStore';
export const UPDATE_FEE_CONFIG_RECORD_IN_STORE = 'updateFeeConfigRecordInStore';
export const UPDATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE = 'updateFoodTechFeeConfigRecordInStore';
export const UPDATE_PAYMENT_METHODS_IN_STORE = 'updatePaymentMethodsInStore';
export const CREATE_FEE_CONFIG_RECORD_IN_STORE = 'createFeeConfigRecordInStore';
export const CREATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE = 'createFoodTechFeeConfigRecordInStore';
export const DELETE_FEE_CONFIG_RECORD_IN_STORE = 'deleteFeeConfigRecordInStore';
export const RESET_FEE_CONFIG_STATE = 'resetFeeConfigState';

// REMITTANCES
export const ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CURR_IN_STORE = "addRemittancesTransactionStackedBarByCurrInStore";
export const ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CHANNE_IN_STORE = "addRemittancesTransactionStackedBarByChanneInStore";
export const ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_METHOD_IN_STORE = "addRemittancesTransactionStackedBarByMethodInStore";
export const ADD_SETTLEMENTS_SUMMARY_RESULTS_IN_STORE = "addSettlementsSummaryResultsInStore";
export const UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE = "updateSettlementsResultsFetchingState";
export const ADD_CROSS_BORDER_PAYMENT_METHODS_IN_STORE = "addCrossBorderPaymentMethodsInStore";
export const ADD_REMITTANCE_SUMMARY_IN_STORE = "addRemittanceSummaryInStore";
export const UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE = "updateIsFetchingRemittanceSummary";
export const ADD_REMITTANCES_BANK_ACCOUNT_IN_STORE = "addRemittancesBankAccountInStore";
export const CREATE_BANK_DATA_IN_STORE = "createBankDataInStore";
export const UPDATE_BANK_DATA_IN_STORE = "updateBankDataInStore";
export const ADD_BANK_LIST_IN_STORE = "addBankListInStore";
export const UPDATE_LEDGER_DATA_IN_STORE = 'updateLedgerData';
export const TOGGLE_LEDGER_LOADER_IN_STORE = "toggleLedgerLoaderInStore"

// SUBSCRIPTION PRICE
export const SET_SUBSCRIPTION_PRICE_DATA_IN_STORE = "setSubscriptionPriceDataInStore";
export const SET_SUBSCRIPTION_DATA_IN_STORE = "setSubscriptionPriceDataInStore";

//smart routing
export const ADD_SMART_ROUTING_LIST_IN_STORE = "addSmartRoutingListInStore";
export const ADD_PAYMENT_CHANNELS_IN_STORE = "addPaymentChannelsInStore";

//File Management
export const FILE_MANAGEMENT_ADD_DATA_IN_STORE = 'feeManagementAddDataInStore';

// Notifications
export const ADD_NOTIFICATIONS_IN_STORE = "addNotificationsInStore";
export const CLEAR_NOTIFICATION_STATE = "clearNotificationState";

//incoices
export const ADD_INVOICES_IN_STORE = "addInvoicesInStore";
export const ADD_INVOICES_DETAILS_IN_STORE = "addInvoicesDetailsInStore";
export const ADD_INVOICES_TEMPLATES_IN_STORE = "addInvoicesTemplatesInStore";
export const ADD_INVOICES_TIMELINE_IN_STORE = "addInvoicesTimeLineInStore";
