import cloneDeep from 'lodash.clonedeep';
import { FileManagementService } from '../../helpers/backend/file_management.service';
import { FETCH_FILE_MANAGEMENT_DATA, POST_FILE_MANAGEMENT_DATA } from '../actions.type';

import { FILE_MANAGEMENT_ADD_DATA_IN_STORE } from '../mutations.type';

export const state = {
    content: []
};

export const mutations = {
    [FILE_MANAGEMENT_ADD_DATA_IN_STORE](state, content) {

        state.content = content.map(((element, index) => {
            element.index = index;
            return element;
        }));
    }
};

export const actions = {
    [FETCH_FILE_MANAGEMENT_DATA]({ commit, rootState }, paymentChannel) {
		return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            FileManagementService.fetchFileManagementRecords(iamportKey, environment, paymentChannel).then((response) => {
                commit(FILE_MANAGEMENT_ADD_DATA_IN_STORE, response.content)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
    [POST_FILE_MANAGEMENT_DATA]({ commit, rootState }, {formData, refundIncluded, currentData}) {
		return new Promise((resolve, reject) => {
            let payload;
            let data = {
                engine_source: formData.display_name,
                file_prefix: formData.display_name,
                offset: formData.offset,
                sheet_number: formData.sheet_number,
                environment: rootState.auth.environment,
                source_date_format: formData.source_date_format,
                display_name: formData.display_name,
                currency: formData.currency,
                portone_key: rootState.auth.currentUser.iamportKey,
                payment_channel_key: formData.payment_channel_key,
                charge_params: formData.charge_params,
                all_headers: formData.all_headers,
                file_type: formData.file_type
            };
            if(formData.file_type === 'transaction') {
                data.header_mapping = {
                    date: formData.fileHeaders[0],
                    amount: formData.fileHeaders[1],
                    merchant_order_ref: formData.fileHeaders[2],
                    channel_order_ref: formData.fileHeaders[3],
                    channel_order_ref1: formData.fileHeaders[4],
                    status: formData.fileHeaders[5]
                }
            } else {
                data.header_mapping = {
                    date: formData.fileHeaders[0],
                    amount: formData.fileHeaders[1],
                    txn_type: formData.fileHeaders[2],
                    txn_ref: formData.fileHeaders[3]
                }
            }
            if(refundIncluded && formData.file_type === 'recon') {
                data.refund_header_mapping = {
                    amount: formData.refundFileHeaders[0],
                    date: formData.refundFileHeaders[1],
                    txn_ref: formData.refundFileHeaders[2],
                    txn_type: formData.refundFileHeaders[3],
                }
                data.refund_offset = formData.refund_offset;
                data.refund_sheet_number = formData.refund_sheet_number;
            }

            if (currentData) {
                payload = cloneDeep(currentData);
            } else {
                // const sourceKey = formData.display_name +  new Date()[Symbol.toPrimitive]('number');
                payload = {
                    // source_name: sourceKey,
                    source_type: "PROVIDER",
                    input_header: {},
                    refund_header_mapping: {},
                    refund_input_header: {},
                    refund_offset: 0,
                    refund_sheet_number: -1,
                    parent_rfm_id: null,
                    created_by: "MERCHANT",
                    file_type: "recon",
                    result_title_map: {}
                };
            }

            if(!refundIncluded) {
                data.refund_header_mapping = {};
                payload.refund_header_mapping = {};
            }
            const finalPayload = {...payload, ...data};

            FileManagementService.postFileManagementForm(finalPayload).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
};