import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const PaymentChannelService = {
  getPaymentChannelCredentials,
  getPreviewPaymentChannels,
  getPaymentChannelPayoutCredentials,
  getSelectedPaymentChannelCredentials,
  getPaymentOnboardingEntry,
  getPaymentMethodsCredentials,
  updatePaymentChannelCredential,
  addPaymentChannelCredential,
  updateEnableAndDefaultStatusForMethod,
  onboardingStatusCheckApi,
  updateInstalmentTypeData,
  addPaymentMethodImportCredData,
  getPreviewIamportCredsData
};

function getPaymentChannelCredentials(merchantUUID,country,paymentMethod, paymentType, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment, "countries": country, "pmt_method": paymentMethod, "pmt_type": paymentType }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/list-pay-channels`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getPreviewPaymentChannels(merchantUUID, chaiPayKey, currency, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment, currency})
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/paymethodsbyKey?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function onboardingStatusCheckApi(merchantUUID, chaiPayKey, environment, psp) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({"iamport_key":chaiPayKey,"env":environment})
  return fetch(`${CHAIPAY_API_URL}/api/onboarding/${psp}/status?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getPaymentChannelPayoutCredentials(merchantUUID, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/list-payout-channels?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function getSelectedPaymentChannelCredentials(merchantUUID,channelName,payType, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment, payType})
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/pay-channels/${channelName}?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function getPaymentOnboardingEntry(merchantUUID, iamport_key,environment, channelName) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({iamport_key,"env":environment})
  return fetch(`${CHAIPAY_API_URL}/api/onboarding/${channelName.toLowerCase()}?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function getPaymentMethodsCredentials(merchantUUID, channel_key, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/pay-methods/${channel_key}?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updatePaymentChannelCredential(merchantUUID, pmtChannelId, channelDetails, country, environment) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment, creds: channelDetails, country: country }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/update-paychannel/${pmtChannelId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function addPaymentChannelCredential(merchantUUID, pmtChannelId, channelDetails, country, environment, is_va_flow) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ environment, creds: channelDetails, country: country, is_va_flow: is_va_flow }),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/add-paychannel/${pmtChannelId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateEnableAndDefaultStatusForMethod(merchantUUID, statusData, channelName) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify( statusData ),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/update-pay-methods/${channelName}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateInstalmentTypeData(merchantUUID, reqData, channelName) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify( reqData ),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/update-pay-methods/${channelName}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function addPaymentMethodImportCredData(channel_key, environment,reqData){
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(reqData),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchants/creds/portone_kr/${channel_key}?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });

}
function getPreviewIamportCredsData(chaiPayKey,environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchants/list-creds/portone_kr/${chaiPayKey}?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
