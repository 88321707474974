import { ReconciliationService } from '../../helpers/backend/reconciliation.service';
import moment from 'moment';
import {
  FETCH_RECONCILIATION_RESULTS,
  FETCH_RECONCILIATIONS,
  FETCH_RECONCILIATION_SUMMARY_RESULTS,
  FETCH_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS,
  FETCH_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY,
  UPDATE_FILE_UPLOAD,
  FILE_UPLOAD,
  CREATE_RECON,
  RECON_DEATILS,
  RECON_TRANSACTIONS,
  RECON_SETTLEMENTS,
  FETCH_CHANNEL_LIST_FOR_RECON,
  FETCH_CHANNEL_LIST,
  CLEAR_RECON_DEATILS,
  EXPORT_MISMATCH_TRANSACTIONS_INTO_CSV,
  EXPORT_RECON_SUMMERY_INTO_CSV,
  FETCH_USER_DATA_BY_PROPERTY_TYPE,
  FETCH_RECONCILIATION_TRANSACTIONS,
  FETCH_UNRECONCILED_SETTLEMENT,
  DOWNLOAD_RECONCILIATION_TRANSACTIONS,
  DOWNLOAD_UNRECONCILED_SETTLEMENT,
  FETCH_INGESTION_SUMMARY,
  FETCH_RECON_METABASE_RESULT,
  FETCH_USER_DATA_BY_PROPERTY_TYPE_LIST
} from '../actions.type';

import {
  ADD_RECONCILIATION_RESULTS_IN_STORE,
  ADD_RECONCILIATION_SUMMARY_RESULTS_IN_STORE,
  ADD_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS_IN_STORE,
  ADD_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY_IN_STORE,
  ADD_RECONCILIATION_SUMMARY_IN_STORE,
  // SET_UPLOADED_DATA_IN_STORE,
  UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE,
  SET_RECON_DEATILS_IN_STORE,
  SET_SELECTED_DATE_FOR_SUMMARY,
  RECON_ADD_CHANNEL_LIST_IN_STORE,
  CLEAR_RECON_DEATILS_IN_STORE,
  ADD_FOOD_TECH_STORE_LIST,
  RECON_METABASE_RESULT_IN_STORE
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  content: [],
  Summarycontent: [],
  pspcontent:[],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
  // uploaded_data:[],
  recon_details:{},
  summary:[],
  storeList: [],
  selected_date_for_summary:"",
  paymentChannelList:[],
  reconMetabaseResult:""
}

export const mutations = {
  // [SET_UPLOADED_DATA_IN_STORE](state, entityDetails) {
  //   state.uploaded_data = [];
  //   state.uploaded_data.push(entityDetails.content.s3_key);
  // },
  [SET_SELECTED_DATE_FOR_SUMMARY](state, selected_value) {
    state.selected_date_for_summary = selected_value;
  },
  [RECON_ADD_CHANNEL_LIST_IN_STORE](state, newChannelListData) {
		state.paymentChannelList = newChannelListData;
	},
  [RECON_METABASE_RESULT_IN_STORE](state, metabaseResult) {
		state.reconMetabaseResult = metabaseResult;
	},
  [ADD_FOOD_TECH_STORE_LIST](state, {storeList, source_name}) {
		state.storeList = storeList.map(element => {
      element.source_name = source_name;
      return element;
    });
  },
  [SET_RECON_DEATILS_IN_STORE](state, entityDetails) {
    state.recon_details = entityDetails
  },
  [CLEAR_RECON_DEATILS_IN_STORE](state) {
    state.recon_details = {}
  },
  [ADD_RECONCILIATION_RESULTS_IN_STORE](state, newTransactionData) {
    state.content = newTransactionData.recon_list;
    state.total_elements = newTransactionData.total;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.total;
  },
  [ADD_RECONCILIATION_SUMMARY_RESULTS_IN_STORE](state, newTransactionData) {
    state.Summarycontent = newTransactionData;
  },
  [ADD_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.Summarycontent = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [ADD_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.pspcontent = newTransactionData.content;
  },
  [ADD_RECONCILIATION_SUMMARY_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.summary = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE](state, isFetching) {
    state.isFetchingData = isFetching;
  }
}

export const getters = {
  getPaymentLinksEntityDetails(state) {
    return state.entityDetails;
  },
  // aftercreateReconData(state) {
  //   return state.uploaded_data;
  // },
}

export const actions = {
  [UPDATE_FILE_UPLOAD](context, { fileData }) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const environment = context.rootState.auth.environment;
    const merchantUUId = context.rootState.auth.currentUser.merchantUuid;
    fileData['environment'] = environment;
    fileData.append('environment', environment);
    return new Promise((resolve, reject) => {
      ReconciliationService.uploadFile(merchantUUId, iamportKey, fileData).then((response) => {
        // context.commit(SET_UPLOADED_DATA_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        let errorObject;
        try {
          errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.Message, { root: true })
        } catch (e) {
          context.commit('notification/error', "Server Error", { root: true })
        }
        reject(error);
      })
    })
  },
  [FILE_UPLOAD](context, fileData) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;

    fileData.append('environment', context.rootState.auth.environment);

    return new Promise((resolve, reject) => {
      ReconciliationService.fileUpload(iamportKey, fileData).then(resolve).catch(reject);
    })
  },
  [FETCH_CHANNEL_LIST_FOR_RECON]({ commit, rootState },{fileType = "", pageType, currency}) {
		return new Promise((resolve, reject) => {
      const environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
			ReconciliationService.getChannelListForMerchant(iamportKey, environment, fileType, pageType, currency).then((response) => {
				commit(RECON_ADD_CHANNEL_LIST_IN_STORE, response.content)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
  [FETCH_CHANNEL_LIST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
			ReconciliationService.getChannelList(iamportKey, environment).then((response) => {
				commit(RECON_ADD_CHANNEL_LIST_IN_STORE, response.data)
				resolve(response);
			}).catch(reject)
		})
  },
  [FETCH_USER_DATA_BY_PROPERTY_TYPE]({ commit, rootState }, data) {
		return new Promise((resolve, reject) => {
      data.environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
			ReconciliationService.getUserDataByPropertyType(iamportKey, data).then((response) => {
        if(data.property_type === 'store_id') {
          commit(ADD_FOOD_TECH_STORE_LIST, {storeList: response.content, source_name: data.source_name})
        }
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
  [FETCH_USER_DATA_BY_PROPERTY_TYPE_LIST]({ commit, rootState }, data) {
		return new Promise((resolve, reject) => {
      data.environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
			ReconciliationService.getUserDataByPropertyTypeList(iamportKey, data).then((response) => {
        // if(data.property_type === 'store_id') {
        //   commit(ADD_FOOD_TECH_STORE_LIST, {storeList: response.content, source_name: data.source_name})
        // }
				resolve(response);
			}).catch((error) => {
        console.log(error);
        
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
  [CREATE_RECON](context, data) {
    data.environment = context.rootState.auth.environment;
    const iamportKey = context.rootState.auth.currentUser.iamportKey;

    return new Promise((resolve, reject) => {
      ReconciliationService.createReconciliation(iamportKey, data).then(resolve).catch(reject);
    })
  },

  [RECON_DEATILS](context, ref) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;

    return new Promise((resolve, reject) => {
      ReconciliationService.reconciliationDetails(iamportKey, ref).then(resolve).catch(reject)
    })
  },

  [RECON_TRANSACTIONS](context, payload) {
    payload.environment = context.rootState.auth.environment;
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    return new Promise((resolve, reject) => {
      ReconciliationService.reconciliationTransactions(iamportKey, payload).then((response) => {
        context.commit(SET_RECON_DEATILS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(errorObject);
      })
    })
  },

  [RECON_SETTLEMENTS](context, payload) {
    payload.environment = context.rootState.auth.environment;
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    return new Promise((resolve, reject) => {
      ReconciliationService.reconciliationSettlement(iamportKey, payload).then((response) => {
        context.commit(SET_RECON_DEATILS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(errorObject);
      })
    })
  },


  [FETCH_RECONCILIATION_RESULTS]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;

      ReconciliationService.fetchReconciliationResults(iamportKey, payload).then((response) => {

        commit(ADD_RECONCILIATION_RESULTS_IN_STORE, response.data)
        resolve(response);
      }).catch(reject);
    })
  },
  [FETCH_INGESTION_SUMMARY]({ rootState }, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload.environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;

      ReconciliationService.fetchIngestionSummary(iamportKey, payload).then(resolve).catch(reject);
    })
  },
  [FETCH_RECONCILIATIONS]({rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;

      ReconciliationService.fetchReconciliations(iamportKey, payload).then(resolve).catch(reject);
    })
  },
  [FETCH_RECONCILIATION_SUMMARY_RESULTS]({ commit, rootState }, payload) {
    commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.key = iamportKey;
      payload.environment = rootState.auth.environment;
      ReconciliationService.fetchReconciliationSummaryResults(iamportKey, payload).then((response) => {
        const paylinks_content = response.content.map((paylinks) => {
          return {
            date: moment(paylinks.key).format('MMM Do YYYY') || "",
            key: paylinks.key,
            total_amount: paylinks.provider_amount_stats.sum,
            total_count: paylinks.provider_amount_stats.count,
            fee_deduction_amount:paylinks.deducted_amount_stats.sum,
            fee_deduction_count:paylinks.deducted_amount_stats.count,
            expected_fee_amount:paylinks.expected_psp_fee_stats.sum,
            expected_fee_count:paylinks.expected_psp_fee_stats.count,
            miss_match_amount: paylinks.psp_fee_difference_stats.sum,
            miss_match_count:paylinks.psp_fee_difference_stats.count,
            tolerance_amount_stats: paylinks.tolerance_amount_stats.sum,
            tolerance_count_stats:paylinks.tolerance_amount_stats.count,
            trans_amount:paylinks.total_amount_stats.sum,
            trans_count:paylinks.total_amount_stats.count,
            refund_amount: paylinks.refund_amount_stats.sum,
            refund_count: paylinks.refund_amount_stats.count,
            settlement_amount: paylinks.settlement_amount_stats.sum - paylinks.refund_amount_stats.sum,
            settlement_count: paylinks.settlement_amount_stats.count,
            unSettlement_amount: paylinks.chaipay_amount_stats.sum,
            unSettlement_count: paylinks.chaipay_amount_stats.count,     
            fee_amount:paylinks.deducted_amount_stats.sum,
            fee_count:paylinks.deducted_amount_stats.count, 
            currency:paylinks.currency,
            other_adjustments: paylinks.other_adjustments
          };
        });
        commit(ADD_RECONCILIATION_SUMMARY_RESULTS_IN_STORE, paylinks_content)
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        resolve(paylinks_content);
      }).catch((error) => {
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS]({ commit, rootState }, { pageIndex, pagesize, from_date, to_date, frequency, recon_type }) {
    commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      const merchantUUId = rootState.auth.currentUser.merchantUuid;
      ReconciliationService.fetchReconciliationSummaryForPspResults(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, frequency, recon_type, environment).then((response) => {
        const paylinks_content = response.content.map((paylinks) => {
          return {
            date: moment(paylinks.key).format('MMM YYYY') || "",
            key:paylinks.key,
            fee_amount: paylinks.deducted_amount_stats.sum,
            total_amount: paylinks.provider_amount_stats.sum,
            settlement_amount: paylinks.settlement_amount_stats.sum,
            currency:paylinks.currency
          };
        });

        const paymentLinksData = {
          content: paylinks_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS_IN_STORE, paymentLinksData)
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY]({ commit, rootState }, { from_date, to_date, recon_type }) {
    commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      const merchantUUId = rootState.auth.currentUser.merchantUuid;
      ReconciliationService.fetchPspResultsForReconciliationSummary(merchantUUId, iamportKey, from_date, to_date, recon_type, environment).then((response) => {
        const paylinks_content = response.content.map((paylinks) => {
          return {
            key:paylinks.key,
            fee_amount: paylinks.deducted_amount_stats.sum,
            settlement_amount: paylinks.settlement_amount_stats.sum,
            total_amount:paylinks.provider_amount_stats.sum,
            currency:paylinks.currency
          };
        });

        const paymentLinksData = {
          content: paylinks_content
        }
        commit(ADD_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY_IN_STORE, paymentLinksData)
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_RECONCILIATION_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_RECONCILIATION_TRANSACTIONS]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;
      ReconciliationService.fetchReconciliationTransactions(iamportKey, payload).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
    })
  },

  [FETCH_UNRECONCILED_SETTLEMENT]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;
      ReconciliationService.fetchUnReconciledSettlements(iamportKey, payload).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
    })
  },

  [DOWNLOAD_RECONCILIATION_TRANSACTIONS]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;
      ReconciliationService.downloadReconciliationTransactions(iamportKey, payload).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
    })
  },

  [DOWNLOAD_UNRECONCILED_SETTLEMENT]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      payload.environment = rootState.auth.environment;
      ReconciliationService.downloadUnReconciledSettlements(iamportKey, payload).then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      })
    })
  },

  [CLEAR_RECON_DEATILS]({ commit }) {
    commit(CLEAR_RECON_DEATILS_IN_STORE)
  },

  [EXPORT_MISMATCH_TRANSACTIONS_INTO_CSV]({ commit, rootState }, data) {
		return new Promise((resolve, reject) => {
      const environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;

      ReconciliationService.exportFeeMismatchTransactions(iamportKey, environment, data).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
		})
	},

  [EXPORT_RECON_SUMMERY_INTO_CSV]({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;

      data.environment = rootState.auth.environment;
      ReconciliationService.exportReconSummery(iamportKey, data).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
		})
  },
  [FETCH_RECON_METABASE_RESULT]({ commit, rootState }, {currency, frequency}) {
    return new Promise((resolve, reject) => {
      const environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
			ReconciliationService.getReconMetabaseResult(iamportKey, environment, currency, frequency).then((response) => {
				commit(RECON_METABASE_RESULT_IN_STORE, response.data)
				resolve(response);
			}).catch(reject)
		})
  },
}

export const namespaced = true;
