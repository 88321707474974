import Common from '../../helpers/Common';
import { AuthService } from '../../helpers/backend/auth.service';
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  SEND_RESET_PASSWORD_LINK,
  RESET_PASSWORD,
  SET_USER_LANGUAGE,
  SEND_EMAIL_VERIFICATION_LINK,
  SET_SANDBOX_MODE_ALERT,
  SET_UPDATE_ALERT,
} from '../actions.type';
import {
  SET_AUTH_USER,
  SET_SANDBOX_MODE_ALERT_IN_STORE,
  SET_USER_LANGUAGE_IN_STORE,
  SET_UPDATE_ALERT_IN_STORE,
} from '../mutations.type';

export const state = {
  currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
  language:  localStorage.getItem('user.language') ? JSON.parse(localStorage.getItem('user.language')) : 'en',
  environment: 'sandbox',
  sandboxModeAlert: true,
  updateAlert: process.env.VUE_APP_UPDATE_ALERT_AVAILABLE === 'true' && localStorage.getItem('updateAlert') ? true : false,
}

export const mutations = {
  [SET_AUTH_USER](state, newValue) {
    state.currentUser = newValue;
    if (newValue == null) {
      window.localStorage.clear();
    } else {
      state.sandboxModeAlert = !newValue.is_live_mode;
      state.updateAlert = process.env.VUE_APP_UPDATE_ALERT_AVAILABLE === 'true' && localStorage.getItem('updateAlert') ? true : false;
      saveState('auth.currentUser', newValue);
    }
  },
  [SET_USER_LANGUAGE_IN_STORE](state, newLang) {
    state.language = newLang;
    saveState('user.language', newLang);
  },
  updateEnvironmentInStore(state, environment) {
    state.environment = environment;
    // console.log('state.environment', state.environment);
    // saveState('environment', environment);
  },
  [SET_SANDBOX_MODE_ALERT_IN_STORE](state, show) {
    state.sandboxModeAlert = show;
  },
  [SET_UPDATE_ALERT_IN_STORE](state, show) {
    state.updateAlert = show;
    localStorage.removeItem('updateAlert');
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  iamportKey(state) {
    return state.currentUser ? state.currentUser.iamportKey : null;
  },
  getCurrentEnvironment(state) {
    return state.environment ? state.environment : 'sandbox';
  },
  isEmailVerified(state) {
    return state.currentUser ? state.currentUser.is_email_verified : false;
  },
  merchantUUID(state) {
    return state.currentUser ? state.currentUser.merchantUuid : null;
  },
  subMerchantUUID(state) {
    return state.currentUser ? state.currentUser.subUserUuid : null;
  },
  selectedLanguage(state) {
    return state.language ? state.language : 'en';
  },
  getUserRolesList(state) {
    return state.currentUser.roles ? state.currentUser.roles : [];
  },
  getMerchantList(state) {
    return state.currentUser.is_master_merchant ? ["master-merchant"] : ["single-merchant"];
  },
  getCrossBorder(state) {
    return state.currentUser.cross_border_enabled ? ["cross-border-merchant"] : ["single-merchant"];
  },
  getUserPermissionsList(state) {
    return state.currentUser.permissions ? state.currentUser.permissions : [] ;
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  getCurrentUserCountry(state) {
    return state.currentUser.country;
  },
  getConvertCountry(state){
    return Common.currencyByCountry(state.currentUser.country);
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init(context) {
    context.dispatch('validate');
  },

  // Logs in the current user.
  [LOGIN](context, { email, password } = {}) {
    // if (getters.loggedIn) return context.dispatch('validate')
    return new Promise((resolve, reject) => {
      AuthService.logIn(email, password).then((response) => {
        context.commit(SET_AUTH_USER, response.data);
        localStorage.setItem("token", response.token);
        if(context.state.currentUser && window.clarity) {
          window.clarity("identify", context.state.currentUser.email_address, context.state.currentUser.iamportKey)
        }
        resolve(response.data);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message);
        context.commit('notification/error', errorObject.Message, { root: true });
        if(errorObject.message){
          context.commit('notification/error', errorObject.message, { root: true });
        }
        reject(errorObject.Message);
      })
    });
  },

  [LOGOUT](context) {
    context.commit(SET_AUTH_USER, null);
  },

  setEnvironment(context, environment) {
    context.commit("updateEnvironmentInStore", environment);
  },

  [REGISTER](context, data) {
    
    // if (getters.loggedIn) return dispatch('validate')
    return new Promise((resolve, reject) => {
      let formData = {
        merchant_name: data.company_name,
        merchant_loginid: data.email,
        merchant_password: data.password,
        country: data.country,
        is_master_merchant: data.is_master_merchant == 'true' ? true : false,
        is_login_allowed: data.is_login_allowed == 'true' ? true : false,
        is_invite_merchant: data.is_invite_merchant == 'true' ? true : false,
        use_master_merchant_creds: data.use_master_merchant_creds == 'true' ? true : false,
        send_kyc_link_email: data.send_kyc_link_email == 'true' ? true : false,
        cross_border_enabled: data.cross_border_enabled == 'true' ? true : false,
        cross_border_model: "PARTNERSHIP"
      };

      if(data.master_merchant_key) {
        formData.master_merchant_key = data.master_merchant_key
      }

      AuthService.register(formData).then((response) => {
        context.commit(SET_AUTH_USER, response.data);
        localStorage.setItem("token", response.token);
        resolve(response.data);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message);
        context.commit('notification/error', errorObject.Message, { root: true });
        reject(errorObject.Message);
      })
    });
  },

  [SEND_RESET_PASSWORD_LINK](context, {email} = {}) {
    return new Promise((resolve, reject) => {
      AuthService.sendResetPasswordLinkToEmail(email).then((response) => {
        context.commit('notification/success', response.Message, { root: true });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message);
        context.commit('notification/error', errorObject.Message, { root: true });
        reject(errorObject.Message);
      })
    });
  },

  [SEND_EMAIL_VERIFICATION_LINK](context) {
    return new Promise((resolve, reject) => {
      const email = context.rootState.auth.currentUser.email_address;
      AuthService.sendEmailVerificationLink(email).then((response) => {
        context.commit('notification/success', response.Message, { root: true });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message);
        context.commit('notification/error', errorObject.Message, { root: true });
        reject(errorObject.Message);
      })
    });
  },

  [RESET_PASSWORD](context, { resetToken, new_password } = {}) {
    return new Promise((resolve, reject) => {
      AuthService.resetPassword(resetToken, new_password).then((response) => {
          context.commit('notification/success', response.Message, { root: true });
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message);
          context.commit('notification/error', errorObject.Message, { root: true });
          reject(error);
        })
    });
  },

  [SET_USER_LANGUAGE](context, newLanguage) {
    context.commit(SET_USER_LANGUAGE_IN_STORE, newLanguage);
  },

  [SET_SANDBOX_MODE_ALERT](context, show) {
    context.commit(SET_SANDBOX_MODE_ALERT_IN_STORE, show);
  },
  [SET_UPDATE_ALERT](context, show) {
    context.commit(SET_UPDATE_ALERT_IN_STORE, show);
  },

  // TODO: Validate using chai pay backend APIs, Validates the current user's token and refreshes it
  // with new data from the API.
  validate(context) {
    if (!context.state.currentUser) return Promise.resolve(null);
    return {};
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export const namespaced = true;