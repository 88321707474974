import { PaymentChannelService } from '../../helpers/backend/payment_channel.service';
import { OnboardingFormService } from "../../helpers/backend/onboarding_form.service";
import * as cloneDeep from "lodash.clonedeep";
import {
	FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_ALL_PAYMENT_CHANNEL_FOR_PREVIEW,
  FETCH_ALL_PAYMENT_PAYOUT_CHANNEL,
	FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS,
	FETCH_PAYMENT_METHODS_CREDENTIALS,
  FETCH_PAYMENT_METHODS_CREDENTIALS_COUNT,
	UPDATE_PAYMENT_METHOD_CREDENTIALS,
	ADD_PAYMENT_METHOD_CREDENTIALS,
	UPDATE_ENABLE_AND_DEFAULT_STATUS,
  FETCH_PAYMENT_ONBOARDING,
  ONBOARDING_STATUS_CHECK,
  FETCH_ONBOARDING_FORM_DATA,
  ADD_ONBOARDING_FORM_DATA,
  UPDATE_INSTALMENT_TYPE_DATA,
  ADD_PAYMENT_METHOD_IMPORT_CREDENTIALS,
  FETCH_PREVIEW_IAMPORT_CREDENTIALS

} from '../actions.type';
import {
  UPDATE_ALL_PAYMENT_CHANNEL_IN_STORE,
  CLEAR_ALL_PAYMENT_CHANNEL_IN_STORE,
  UPDATE_ALL_PREVIEW_PAYMENT_CHANNEL_IN_STORE,
  UPDATE_ALL_PAYMENT_PAYOUT_CHANNEL_IN_STORE,
  UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE,
  UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE,
  UPDATE_PAYMENT_METHODS_DETAILS_COUNT_IN_STORE,
  UPDATE_PAYMENT_ONBOARDING_DETAILS_IN_STORE,
  UPDATE_ONBOARDING_FORM_DATA ,
  UPDATE_PAYMENT_CHANNEL_ONBOARDING_FORM_DATA,
  ADD_IMPORT_CREDS_IN_STORE,
  UPDATE_IAMPORT_CREDENTIALS_IN_STORE
} from '../mutations.type';

export const state = {
	channels: {},
  payoutChannels:{},
	selectedChannelCreds: [],
  previewChannels: {},
  selectedChannelMethods: [],
  methosDetailsCount:{},
  onboardingDetailsEntry:{},
  onboardingFormData:{},
  reqBody:{},

}

export const mutations = {
  [UPDATE_ALL_PAYMENT_CHANNEL_IN_STORE](state, { entityKey, entityDetails }) {
    state.channels[entityKey] = entityDetails
    state.channels = cloneDeep(state.channels)
  },

  [UPDATE_ONBOARDING_FORM_DATA](state, formdata) {
    state.onboardingFormData = formdata
  },

  [UPDATE_PAYMENT_CHANNEL_ONBOARDING_FORM_DATA](state, formvalues){
    state.reqBody = formvalues;
  },

  [CLEAR_ALL_PAYMENT_CHANNEL_IN_STORE](state, entityDetails) {
    state.channels = entityDetails
  },
  [UPDATE_ALL_PREVIEW_PAYMENT_CHANNEL_IN_STORE](state, entityDetails ) {
    state.previewChannels = entityDetails
  },
  [UPDATE_ALL_PAYMENT_PAYOUT_CHANNEL_IN_STORE](state, { entityKey, entityDetails }) {
    state.payoutChannels[entityKey] = entityDetails
    state.payoutChannels = cloneDeep(state.payoutChannels)
  },
  [UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE](state, entityDetails ) {
    state.selectedChannelCreds = entityDetails
    entityDetails.forEach((channel) => {
      if(channel.is_enabled){
        state.methosDetailsCount[channel.key]={}
      }
    });
  },
  [UPDATE_PAYMENT_ONBOARDING_DETAILS_IN_STORE](state, entityDetails ) {
    state.onboardingDetailsEntry = entityDetails
  },
  [ADD_IMPORT_CREDS_IN_STORE](state, importCreds) {
    state.importCreds = importCreds;
  },
  [UPDATE_IAMPORT_CREDENTIALS_IN_STORE](state, iamportUpdateCreds) {
    for(let key in iamportUpdateCreds) {
      if(!iamportUpdateCreds[key].available.includes(iamportUpdateCreds[key].current)) {
        iamportUpdateCreds[key].current = "NA";
      }
    }
    state.iamportUpdateCreds = iamportUpdateCreds
  },
  [UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE](state, entityDetails ) {
    state.selectedChannelMethods = cloneDeep(entityDetails) 
  },
  [UPDATE_PAYMENT_METHODS_DETAILS_COUNT_IN_STORE](state, { response, channel_key } ) {
    var walletLength = response.filter(function(item){
      return item.sub_type == "WALLET";
    }).length;
    var cardLength = response.filter(function(item){
      return item.type == "CARD" && item.sub_type == "INT_CREDIT_CARD";
    }).length;
    var atmCardLength = response.filter(function(item){
      return item.type == "CARD" && item.sub_type == "ATM_CARD";
    }).length;
    var allLength = response.filter(function(item){
      return item.type == "ALL";
    }).length;
    var walletEnabledLength = response.filter(function(item){
      return item.sub_type == "WALLET" && item.is_enabled;
    }).length;
    var cardEnabledLength = response.filter(function(item){
      return item.type == "CARD" && item.sub_type == "INT_CREDIT_CARD" && item.is_enabled;
    }).length;
    var atmCardEnabledLength = response.filter(function(item){
      return item.type == "CARD" && item.sub_type == "ATM_CARD" && item.is_enabled;
    }).length;
    var allEnabledLength = response.filter(function(item){
      return item.type == "ALL" && item.is_enabled;
    }).length;
    var subScriptionEnabledLength = response.filter(function(item){
      return item.type == "SUBSCRIPTION" && item.is_enabled;
    }).length;
    var dataToStore = {
        wallet:walletLength,
        card:cardLength,
        atmCard:atmCardLength,
        all:allLength,
        walletEnabled:walletEnabledLength,
        cardEnabled:cardEnabledLength,
        atmCardEnabled:atmCardEnabledLength,
        allEnabled:allEnabledLength,
        subscriptionEnabled:subScriptionEnabledLength
    }
    state.methosDetailsCount[channel_key] = dataToStore
    state.methosDetailsCount = cloneDeep(state.methosDetailsCount)
  },
}

export const getters = {
  listOfCountry(state) {
    const allChannel = state.selectedChannelCreds;
    const arrayOfCountry = [];
      if(allChannel.length > 0){
        allChannel.forEach((channel) => {
          if(channel.is_enabled){
            arrayOfCountry.push(channel.country)
          }
        });
      }
    return arrayOfCountry;
  },
  listOfCountryForAddCred(state) {
    const allChannel = state.selectedChannelCreds;
    const arrayOfCountry = [];
    if(allChannel.length > 0){
      allChannel.forEach((channel) => {
        if(!channel.is_enabled){
          if(channel.country == "GLOBAL") {
            if(channel.key === 'ASIAPAY_GLOBAL' || channel.key === 'GBPRIMEPAY') {
              arrayOfCountry.push({
                "key":channel.country,
                "value":"GLOBAL"
              });
            } else {
              arrayOfCountry.push({
                "key":channel.country,
                "value":"All"
              });
            }
          } else {
            let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
            arrayOfCountry.push({
              "key":channel.country,
              "value": regionNames.of(channel.country)
            });
          }
        }
      });
    }
    return arrayOfCountry;
  },
  paymentChaneelSetupdata(state) {
    var selectedChannel = {};
    for (var v in state.channels) {
      if(state.channels[v].is_enabled) {
        selectedChannel[v] = state.channels[v];
      }
    }
    return selectedChannel;
  },
  walletsForPreview(state) {
    var wallets = [];
    for (var v in state.previewChannels["WALLET"]) {
      if(state.previewChannels["WALLET"][v].is_enabled) {
        wallets.push(state.previewChannels["WALLET"][v])
      }
    }
    return wallets;
  },
  bnplForPreview(state) {
    var wallets = [];
    for (var v in state.previewChannels["BNPL"]) {
      if(state.previewChannels["BNPL"][v].is_enabled) {
        wallets.push(state.previewChannels["BNPL"][v])
      }
    }
    return wallets;
  },
  qrCodeForPreview(state) {
    var wallets = [];
    for (var v in state.previewChannels["QR_CODE"]) {
      if(state.previewChannels["QR_CODE"][v].is_enabled) {
        wallets.push(state.previewChannels["QR_CODE"][v])
      }
    }
    return wallets;
  },
  walletsForPreviewTopImages(state) {
    var walletsImg = [];
    for (var v in state.previewChannels["WALLET"]) {
      if( v < 3) {
        walletsImg.push(state.previewChannels["WALLET"][v].logo)
      }
    }
    return walletsImg;
  },
  bnplForPreviewTopImages(state) {
    var walletsImg = [];
    for (var v in state.previewChannels["BNPL"]) {
      if( v < 3) {
        walletsImg.push(state.previewChannels["BNPL"][v].logo)
      }
    }
    return walletsImg;
  },
  qrCodeForPreviewTopImages(state) {
    var walletsImg = [];
    for (var v in state.previewChannels["QR_CODE"]) {
      if( v < 3) {
        walletsImg.push(state.previewChannels["QR_CODE"][v].logo)
      }
    }
    return walletsImg;
  },
  atmCardsForPreview(state) {
    var cards = state.previewChannels["CARD"];
    var cardsData = []
    for (var v in cards) {
      if( cards[v].is_enabled && cards[v].is_default && cards[v].sub_type == 'ATM_CARD') {
        cardsData.push(cards[v])
      }
    }
    return cardsData;
  },
  cardsForPreview(state) {
    var cards = state.previewChannels["CARD"];
    var cardsData = []
    for (var v in cards) {
      if( cards[v].is_enabled && cards[v].is_default && cards[v].sub_type == 'INT_CREDIT_CARD' && cards[v].tokenization_possible) {
        cardsData.push(cards[v])
      }
    }
    return cardsData;
  },
  cardsForPreviewWithoutTokenization(state) {
    var cards = state.previewChannels["CARD"];
    var cardsData = []
    for (var v in cards) {
      if( cards[v].is_enabled && cards[v].is_default && cards[v].sub_type == 'INT_CREDIT_CARD' && !cards[v].tokenization_possible) {
        cardsData.push(cards[v])
      }
    }
    return cardsData;
  },
  cryptoForPreviewWithoutTokenization(state) {
    var cryptos = state.previewChannels["CRYPTO"];
    var cryptosData = []
    for (var v in cryptos) {
      if( cryptos[v].is_enabled && cryptos[v].is_default) {
        cryptosData.push(cryptos[v])
      }
    }
    return cryptosData;
  },
  codForPreview(state) {
    var cods = state.previewChannels["COD"];
    var codsData = []
    for (var v in cods) {
      if( cods[v].is_enabled) {
        codsData.push(cods[v])
      }
    }
    return codsData;
  },
  bankTransferForPreview(state) {
    var bankTransfer = state.previewChannels["BANK_TRANSFER"];
    var bankTransferData = []
    for (var v in bankTransfer) {
      if( bankTransfer[v].is_enabled && bankTransfer[v].is_default) {
        bankTransferData.push(bankTransfer[v])
      }
    }
    return bankTransferData;
  },
  bankTransferPreviewForDirect(state) {
    var bankTransfer = state.previewChannels["BANK_TRANSFER"];
    var bankTransferData = []
    for (var v in bankTransfer) {
      if( bankTransfer[v].is_enabled && bankTransfer[v].is_default && bankTransfer[v].payment_channel_key == "EPAY") {
        bankTransferData.push(bankTransfer[v])
      }
    }
    return bankTransferData;
  },
  paymentChaneelNotSetupdata(state) {
    var selectedChannel = {};
    for (var v in state.channels) {
      if(!state.channels[v].is_enabled) {
        selectedChannel[v] = state.channels[v];
      }
    }
    return selectedChannel;
  },
  topBarData(state) {
    var selectedChannel = {};
    var queryParams = new URLSearchParams(window.location.search)
    var qrcode_channelName = queryParams.get("channelName")
    for (var v in state.channels) {
      if(state.channels[v].key == qrcode_channelName) {
        selectedChannel = state.channels[v];
      }
    }
    return selectedChannel;
  },
  topBarPayoutData(state) {
    var selectedChannel = {};
    var queryParams = new URLSearchParams(window.location.search)
    var qrcode_channelName = queryParams.get("channelName")
    for (var v in state.channels) {
      if(state.channels[v].key == qrcode_channelName) {
        selectedChannel = state.channels[v];
      }
    }
    return selectedChannel;
  },
  dataForWalletType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfWallets = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "WALLET"){
            arrayOfWallets.push(channel);
          }
        });
      }
    return arrayOfWallets;
  },
  dataForBnplType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfBnpls = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "BNPL"){
            arrayOfBnpls.push(channel);
          }
        });
      }
    return arrayOfBnpls;
  },
  dataForEMIType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfEmis = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "EMI"){
            arrayOfEmis.push(channel);
          }
        });
      }
    return arrayOfEmis;
  },
  dataForUPIType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfUpis = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "UPI"){
            arrayOfUpis.push(channel);
          }
        });
      }
    return arrayOfUpis;
  },
  dataForNetBankingType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfNetBankings = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "NET_BANKING"){
            arrayOfNetBankings.push(channel);
          }
        });
      }
    return arrayOfNetBankings;
  },

  dataForQRCodeType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfQRCodes = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "QR_CODE"){
            arrayOfQRCodes.push(channel);
          }
        });
      }
    return arrayOfQRCodes;
  },

  dataForOTCType(state){
    const allMethods = state.selectedChannelMethods;
    const arrayOfOTC = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "OTC"){
            arrayOfOTC.push(channel);
          }
        });
      }
    return arrayOfOTC;

  },

  dataForCryptoType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfCryptos = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "CRYPTO"){
            arrayOfCryptos.push(channel);
          }
        });
      }
    return arrayOfCryptos;
  },
  dataForBankTransferType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfBankTransfer = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "BANK_TRANSFER"){
            arrayOfBankTransfer.push(channel);
          }
        });
      }
    return arrayOfBankTransfer;
  },
  dataForCodType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfWallets = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "COD"){
            arrayOfWallets.push(channel);
          }
        });
      }
    return arrayOfWallets;
  },
  dataForPayByBankType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfPayByBank = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "PAY_BY_BANK"){
            arrayOfPayByBank.push(channel);
          }
        });
      }
    return arrayOfPayByBank;
  },

  dataForDirectBankTransferType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfWallets = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "DIRECT_BANK_TRANSFER"){
            arrayOfWallets.push(channel);
          }
        });
      }
    return arrayOfWallets;
  },


  dataForAllType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfWallets = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "ALL"){
            arrayOfWallets.push(channel);
          }
        });
      }
    return arrayOfWallets;
  },
  dataForCardType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfCards = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "CARD" && channel.sub_type == "INT_CREDIT_CARD"){
            arrayOfCards.push(channel);
          }
        });
      }
    return arrayOfCards;
  },

  dataForSmartButtonType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfSmartCards = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "SMARTBUTTON" && channel.sub_type == "APPLEPAY"){
            arrayOfSmartCards.push(channel);
          }
        });
      }
    return arrayOfSmartCards;
  },

  dataForCreditDebitCardType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfCards = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "CARD" && channel.sub_type == "INT_CREDIT_DEBIT_CARD"){
            arrayOfCards.push(channel);
          }
        });
      }
    return arrayOfCards;
  },

  dataForInstallmentType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfInstallment = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "INSTALLMENT"){
            arrayOfInstallment.push(channel);
          }
        });
      }
    return arrayOfInstallment;
  },
  dataForAtmCardType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfCards = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "CARD" && channel.sub_type == "ATM_CARD"){
            arrayOfCards.push(channel);
          }
        });
      }
    return arrayOfCards;
  },
  dataForDebitCardType(state) {
    const allMethods = state.selectedChannelMethods;
    const arrayOfDebitCards = [];
      if(allMethods.length > 0){
        allMethods.forEach((channel) => {
          if(channel.type == "CARD" && channel.sub_type == "INT_DEBIT_CARD"){
            arrayOfDebitCards.push(channel);
          }
        });
      }
    return arrayOfDebitCards;
  },
  dataForVaBankTransferType(state) {
    return state.selectedChannelMethods.filter(channel => channel.type == "VA_BANK_TRANSFER");
  },
  dataForDirectDebitType(state) {
    return state.selectedChannelMethods.filter(channel => channel.type == "DIRECT_DEBIT");
  },
  dataForSubscriptionType(state) {
    return state.selectedChannelMethods.filter((channel) => channel.type === "SUBSCRIPTION_CARD");
  },
  dataForSubscriptionBankTransferType(state) {
    return state.selectedChannelMethods.filter((channel) => channel.type === "SUBSCRIPTION_BANK_TRANSFER");
  },
  dataForSubscriptionDirectDebitType(state) {
    return state.selectedChannelMethods.filter((channel) => channel.type === "SUBSCRIPTION_DIRECT_DEBIT");
  },
}

export const actions = {
  [FETCH_ALL_PAYMENT_CHANNEL](context, { country , paymentMethod, merchant_uuids, paymentType }) {
    return new Promise((resolve, reject) => {
      context.commit(CLEAR_ALL_PAYMENT_CHANNEL_IN_STORE, {})
      let merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      if(merchant_uuids){
        merchantUUID = merchant_uuids;
      }
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPaymentChannelCredentials(merchantUUID,country,paymentMethod, paymentType, environment).then((response) => {
        (response || []).forEach((payChannel) => {
          context.commit(UPDATE_ALL_PAYMENT_CHANNEL_IN_STORE, {entityKey: payChannel.key, entityDetails: payChannel})
        });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_ALL_PAYMENT_CHANNEL_FOR_PREVIEW](context, { chaiPayKey, currency, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      if(merchant_uuids){
        chaiPayKey = merchant_uuids.key;
      }
      PaymentChannelService.getPreviewPaymentChannels(merchantUUID,chaiPayKey,currency, environment).then((response) => {
        context.commit(UPDATE_ALL_PREVIEW_PAYMENT_CHANNEL_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [ONBOARDING_STATUS_CHECK](context, { chaiPayKey, merchant_uuids, psp }) {
    return new Promise((resolve, reject) => {
      const environment = context.rootState.auth.environment;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      if(merchant_uuids){
        chaiPayKey = merchant_uuids.key;
      }
      PaymentChannelService.onboardingStatusCheckApi(merchantUUID, chaiPayKey, environment, psp).then((response) => {
        // context.commit(UPDATE_ALL_PREVIEW_PAYMENT_CHANNEL_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_ALL_PAYMENT_PAYOUT_CHANNEL](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPaymentChannelPayoutCredentials(merchantUUID, environment).then((response) => {
        (response || []).forEach((payChannel) => {
          context.commit(UPDATE_ALL_PAYMENT_PAYOUT_CHANNEL_IN_STORE, {entityKey: payChannel.key, entityDetails: payChannel})
        });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS](context, { channelName, payType, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getSelectedPaymentChannelCredentials(merchantUUID,channelName,payType, environment).then((response) => {
          context.commit(UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE, response.content)
          // context.commit(UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE, [])
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_ONBOARDING](context, { channelName, payType, merchant_key }) {
    return new Promise((resolve, reject) => {
      let iamport_key;
      if(merchant_key){
        iamport_key = merchant_key;
      }else{
        iamport_key = context.rootState.auth.currentUser.iamportKey;
      }
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPaymentOnboardingEntry(merchantUUID, iamport_key, environment,channelName,payType).then((response) => {
          context.commit(UPDATE_PAYMENT_ONBOARDING_DETAILS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_METHODS_CREDENTIALS](context, { channel_key, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPaymentMethodsCredentials(merchantUUID,channel_key, environment).then((response) => {
          context.commit(UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE, response)
          context.commit(UPDATE_PAYMENT_METHODS_DETAILS_COUNT_IN_STORE, {response:response,channel_key:channel_key})
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [FETCH_PAYMENT_METHODS_CREDENTIALS_COUNT](context, { channel_key, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPaymentMethodsCredentials(merchantUUID,channel_key, environment).then((response) => {
          context.commit(UPDATE_PAYMENT_METHODS_DETAILS_COUNT_IN_STORE, {response:response,channel_key:channel_key})
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [UPDATE_PAYMENT_METHOD_CREDENTIALS](context, { channelId, payChannel, payType, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      // context.commit(UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE, { entityKey: channelId, entityDetails: payChannel })
      const updatedCredentials = payChannel.creds;
			const country = payChannel.country;
      const environment = context.rootState.auth.environment;
      PaymentChannelService.updatePaymentChannelCredential(merchantUUID, channelId, updatedCredentials, country, environment).then((response) => {
        // context.dispatch(`${FETCH_PAYMENT_CHANNEL_CREDENTIALS}`);
				var queryParams = new URLSearchParams(window.location.search)
				context.dispatch(`${FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS}`, {
					channelName: queryParams.get("channelName"),
					payType: payType,
          merchant_uuids
				});
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [ADD_PAYMENT_METHOD_CREDENTIALS](context, { channelId, payChannel, payType, is_va_flow, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      // context.commit(UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE, { entityKey: channelId, entityDetails: payChannel })
      const updatedCredentials = payChannel.creds;
      const country = payChannel.country;
      const environment = context.rootState.auth.environment;
      PaymentChannelService.addPaymentChannelCredential(merchantUUID, channelId, updatedCredentials, country, environment, is_va_flow).then((response) => {
        // context.dispatch(`${FETCH_PAYMENT_CHANNEL_CREDENTIALS}`);
				var queryParams = new URLSearchParams(window.location.search)
				context.dispatch(`${FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS}`, {
					channelName: queryParams.get("channelName"),
					payType: payType,
          merchant_uuids
				});
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [UPDATE_ENABLE_AND_DEFAULT_STATUS](context, { statusData, countryForMethod, merchant_uuids }) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      const environment = context.rootState.auth.environment;
      statusData['environment'] = environment;
      PaymentChannelService.updateEnableAndDefaultStatusForMethod(merchantUUID, statusData, countryForMethod).then((response) => {
				context.dispatch(`${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {
					channel_key:countryForMethod,
          merchant_uuids
				});
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_ONBOARDING_FORM_DATA](context, { merchant_uuids }) {
    
    return new Promise((resolve, reject) => {
      var merchantUUID = "";
      if(merchant_uuids){
        merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      const environment = context.rootState.auth.environment;
      OnboardingFormService.fetchonboardingformdata(merchantUUID, environment).then((response) => {
        context.commit(UPDATE_ONBOARDING_FORM_DATA, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.message, { root: true })
        reject(error);
      })
    })
  },


  [ADD_ONBOARDING_FORM_DATA](context, {reqBody}) {
    
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment; 
      OnboardingFormService.addonboardingformdata(merchantUUID, environment, reqBody).then((response) => {
        context.commit(UPDATE_PAYMENT_CHANNEL_ONBOARDING_FORM_DATA, {formvalues:response})
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        console.log(errorObject)
        context.commit('notification/error', errorObject.statusChannelReason, { root: true })
        reject(error);
      })
    })
  },
  [UPDATE_INSTALMENT_TYPE_DATA](context, {reqBody, channelName, merchant_uuids}) {
    return new Promise((resolve, reject) => {
      if(merchant_uuids){
        var merchantUUID = merchant_uuids;
      }else{
        merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      }
      // const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      reqBody.environment = context.rootState.auth.environment; 
      PaymentChannelService.updateInstalmentTypeData(merchantUUID, reqBody, channelName).then((response) => {
        // context.commit(UPDATE_PAYMENT_CHANNEL_ONBOARDING_FORM_DATA, {formvalues:response})
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        console.log(errorObject)
        context.commit('notification/error', errorObject.statusChannelReason, { root: true })
        reject(error);
      })
    })
  },

  [ADD_PAYMENT_METHOD_IMPORT_CREDENTIALS](context, reqBody) {
    return new Promise((resolve, reject) => {
      
      // context.commit(UPDATE_PAYMENT_CHANNEL_DETAILS_IN_STORE, { entityKey: channelId, entityDetails: payChannel })
      const environment = context.rootState.auth.environment;
      const client_key = context.rootState.auth.currentUser.iamportKey;
      PaymentChannelService.addPaymentMethodImportCredData(client_key, environment, reqBody).then((response) => {
        // context.dispatch(`${FETCH_PAYMENT_CHANNEL_CREDENTIALS}`);
        context.commit(ADD_IMPORT_CREDS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.error, { root: true })
        reject(error);
      })
    })
  },


  [FETCH_PREVIEW_IAMPORT_CREDENTIALS](context) {
    return new Promise((resolve, reject) => {
      const client_key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      PaymentChannelService.getPreviewIamportCredsData(client_key, environment).then((response) => {
        context.commit(UPDATE_IAMPORT_CREDENTIALS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.error, { root: true })
        reject(error);
      })
    })
  },

}

export const namespaced = true;
